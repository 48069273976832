export function textLengthLimit(perex: string, limit = 750): string {
	if (perex.length <= limit) {
		return perex;
	}

	const words = perex.split(/\s+/u);
	let result = "";

	for (const word of words) {
		if (result.length + word.length <= limit) {
			result += `${word} `;
		} else {
			break;
		}
	}

	return `${result.trim()}…`;
}
